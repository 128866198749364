<template>
	<main>
		<JobFilterSelection
			:selected="selected"
			:job-fields="jobFields"
			:get-possible-filter="getPossibleFilter"
			:user="user"
			@set-job-filter="setJobFilter($event)"
			@update="update($event)"
		>
			{{ buttonText }}
		</JobFilterSelection>
	</main>
</template>

<script lang="ts">
import { IAPIJobFieldTree, IAPIJobFilter, type IAPILoginUser } from '@hokify/common';
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import JobFilterSelection from '../components/JobFilterSelection.vue';
import { EventBus } from '../eventbus';

export default defineComponent({
	name: 'JobAlarmPage',
	layout: 'user',
	middleware: 'auth',
	components: {
		JobFilterSelection
	},
	props: {
		getPossibleFilter: { type: Function, required: true },
		selected: { type: Object as PropType<IAPIJobFilter>, default: () => ({}) },
		jobFields: { type: Array as PropType<IAPIJobFieldTree[]>, default: () => [] },
		buttonText: { type: String, default: 'Jobalarm erstellen' },
		user: { type: Object as PropType<IAPILoginUser>, required: true }
	},
	emits: ['set-job-filter', 'update'],
	methods: {
		setJobFilter(event: any) {
			EventBus.$emit('set-job-filter', event);
			this.$emit('set-job-filter', event);
		},
		update(event: any) {
			EventBus.$emit('update', event);
			this.$emit('update', event);
		}
	}
});
</script>
